function show_contact() {
  let ml = "cekho3t4fu%.E-Fa0dCsDrnlimA2y",
    mi = ":5B?:K@3E18:5D:KKI?HG64:5JHF84:7@L9E?04;A1:KK:K@0G?CC:5D:KKI?HG=GHF2:KK:5<HF84:7@L9E?04;A1:5B:K>?:5<",
    o = "";
  for (var j = 0, l = mi.length; j < l; j++) {
    o += ml.charAt(mi.charCodeAt(j) - 48);
  }
  document.getElementById("eeb-855007-699561").innerHTML = decodeURIComponent(o);
}

function show_tel() {
  let ml = "A3r5C8thFle7E0a1%D496fB2",
    mi = "@14>@G=72:E@1A@GG6:9@10@GFBC?3D;5513D11@GG@1<@GFBC@G=?3D;5@G=513D11@14@G8>@1<",
    o = "";
  for (var j = 0, l = mi.length; j < l; j++) {
    o += ml.charAt(mi.charCodeAt(j) - 48);
  }
  document.getElementById("eeb-358075-593945").innerHTML = decodeURIComponent(o);
}


let firstScroll = false;

window.onscroll = function () {
  const scrollLimit = 200;
  if (window.scrollY > scrollLimit && !firstScroll) {
    firstScroll = true;
    show_contact();
    show_tel();
  }
};

function scroll_to_id(id) {
  document.getElementById(id).scrollIntoView();
}

const features_btn = document.getElementById('show-more-features');
const contact_btn = document.getElementById('show-more-contact');

if (features_btn !== null) {
  document.addEventListener('DOMContentLoaded', function () {
    features_btn
      .addEventListener('click', function () {
        scroll_to_id('features')
      })
  });
}

if (contact_btn !== null) {
  document.addEventListener('DOMContentLoaded', function () {
    contact_btn
      .addEventListener('click', function () {
        scroll_to_id('contact')
      })
  });
}
